import type { GatsbySSR, WrapPageElementNodeArgs } from "gatsby"
import React from "react"
import { I18nextProvider } from "react-i18next"
import { PageContextData, PageContextProvider } from "./src/context/PageContext"
import { i18next } from "./src/i18n/i18next"

/**
 * gatsby-ssr ja gatsby-browser API metodit
 */

// Add Microsoft Clarity tracking script
export const onRenderBody: GatsbySSR["onRenderBody"] = ({ setHeadComponents }) => {
  setHeadComponents([
    <script
      key="microsoft-clarity"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "qtlactfhl6");
        `,
      }}
    />,
  ])
}

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  // i18next laitetaan root elementin ulkopuolelle koska
  // - rootin ulkopuolella komponentit ei vaihdu navigoidessa sivulta toiselle: sitä ei tarvitse alustaa kuin kerran
  // - I18NextProvider dokumentaatiosta: "When to use: You will only need to use the provider in scenarios for SSR"
  // - Gatsby dokumentaatio sanoo, että wrapRootElement on oikea paikka lisätä Provider komponentit
  return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>
}

export const wrapPageElement: GatsbySSR["wrapPageElement"] = ({
  element,
  props,
}: WrapPageElementNodeArgs<Record<string, unknown>, PageContextData>) => {
  // console.debug("wrapPageElement props", props)
  return <PageContextProvider value={props.pageContext}>{element}</PageContextProvider>
}
